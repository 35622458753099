'use client';

import { useGetSelf } from '@/hooks/useGetSelf';
import { BASE_BACKEND_URL } from '@/requests/constants';
import type { Self } from '@/store/types';
import Intercom from '@intercom/messenger-js-sdk';
import { getCookie } from 'cookies-next';
import { DateTime } from 'luxon';

function IntercomInit({
  self,
  children,
}: {
  self: Self;
  children: React.ReactNode;
}) {
  Intercom({
    app_id: 'ilbv7ji4',
    region: 'eu',
    user_id: self.id,
    name: self.communicationName,
    email: self.email,
    created_at: DateTime.fromISO(self.createdAt).toUnixInteger(),
    company: {
      company_id: getCookie('customer_id'),
    },
    phone: self?.phoneNumber,
    avatar: {
      type: 'avatar',
      image_url: self?.profilePicture?.get,
    },
    hide_default_launcher: true,
  });

  return <>{children}</>;
}

export default function IntercomWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const { data: self } = useGetSelf();

  const isDev = BASE_BACKEND_URL?.includes('dev.api');

  if (self && !isDev)
    return <IntercomInit self={self}>{children}</IntercomInit>;

  return <>{children}</>;
}
