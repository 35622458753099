import { setAuthCookies } from '@/app/(auth)/login/utils';
import type { RESPONSE_SUCCESS } from '@/app/(auth)/types';
import { fetchApi } from '@/requests/api';
import { BASE_BACKEND_URL } from '@/requests/constants';
import { useIsFetching } from '@tanstack/react-query';
import { getCookies } from 'cookies-next';
import { DateTime } from 'luxon';
import { usePathname } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';
import { ANONYMOUS_ROUTES } from '../use-auth-state';
import { useLogout } from '../useLogout';
import { useWindowFocus } from './use-window.focus';

export function useRefreshValidation() {
  const focused = useWindowFocus();
  const isFetching = useIsFetching();
  const pathname = usePathname();
  const [refreshingToken, setRefreshingToken] = useState(false);
  const cookies = getCookies();
  const { clearUserData } = useLogout();
  const refreshAuthToken = useCallback(async () => {
    if (!refreshingToken) {
      try {
        setRefreshingToken(true);
        const res = await fetchApi({
          url: `${BASE_BACKEND_URL}/accounts/users/auth/login/refresh/`,
          method: 'POST',
        });
        if (!res.ok) {
          throw new Error('Failed to refresh token');
        }
        const data = (await res.json()) as RESPONSE_SUCCESS;
        setAuthCookies(data);
      } catch (e) {
        console.info(e);
      } finally {
        setRefreshingToken(false);
      }
    }
  }, [refreshingToken]);
  useEffect(() => {
    if (
      !cookies?.token_exp &&
      !pathname.includes('/login') &&
      ANONYMOUS_ROUTES.some((r) => pathname.includes(r))
    ) {
      clearUserData();
      return;
    }
    if (cookies?.token_exp && !refreshingToken) {
      // Check if token is about to expire (less than 10 min left). If so, refresh it
      const tokenExpires = DateTime.fromISO(
        decodeURIComponent(cookies.token_exp)
      );
      if (tokenExpires.diffNow().as('minutes') < 10 && isFetching === 0) {
        console.info('Token is about to expire, refreshing');
        refreshAuthToken();
      }
    }
  }, [
    clearUserData,
    cookies.token_exp,
    focused,
    isFetching,
    pathname,
    refreshAuthToken,
    refreshingToken,
  ]);
}
