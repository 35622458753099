import { AspectRatio } from '@/components/ui/aspect-ratio';
import { cn } from '@/lib/cn';
import Image, { type ImageProps } from 'next/image';
import { useState } from 'react';

export function LogoWithFallback(
  props: ImageProps & { ratio?: number; fallbacksrc?: string }
) {
  const {
    src,
    className,
    ratio,
    fallbacksrc = '/assets/img/default-fallback-image.png',
  } = props;
  const [imgSrc, setImgSrc] = useState(src);
  return (
    <div className={cn(className)}>
      <AspectRatio ratio={ratio ?? 1 / 1}>
        <Image
          {...props}
          className={cn('object-contain', className)}
          alt={props.alt || 'Service logo'}
          src={imgSrc}
          onError={() => setImgSrc(fallbacksrc)}
        />
      </AspectRatio>
    </div>
  );
}

export const PigelloLogo = ({ className }: { className?: string }) => {
  return (
    <Image
      width={80}
      height={80}
      src={'/assets/img/pigello-logo-notext-graphite.svg'}
      alt={`Pigello logga`}
      className={className}
    />
  );
};
