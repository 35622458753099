'use client';

import { useBulkTabStore } from '@/store';
import { useEffect, useRef } from 'react';

export const GlobalBeforeUnloadListener = () => {
  const { initialize, onUnload } = useBulkTabStore();
  const hasBound = useRef(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    //has to force only one run
    if (hasBound.current) return;
    hasBound.current = true;

    initialize();

    window.addEventListener('beforeunload', () => {
      onUnload();
    });
  }, []);

  return <></>;
};
