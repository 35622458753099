 import type { Organization } from '@pigello/pigello-matrix';

import { OrganizationConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetOrganization = (
  options: useGetGenericInstanceProps<Organization>
) => {
  return useGetInstance<Organization>({
    ...options,
    config: OrganizationConfig,
    modelName: 'organization'
  });
};

export const useGetOrganizationList = (
  options: useGetGenericListProps<Organization>
) => {
  return useGetList<Organization>({
    ...options,
    config: OrganizationConfig,
    modelName: 'organization'
  });
};
