export const groupByPolyfill = (): void => {
  const hasGroup = typeof Object.groupBy !== 'undefined';

  if (!hasGroup) {
    const groupBy = <T, K extends string | number | symbol>(
      arr: T[],
      callback: (item: T, index: number, array: T[]) => K
    ): Record<K, T[]> => {
      return arr.reduce(
        (acc: Record<K, T[]>, item, index, array) => {
          const key = callback(item, index, array);
          acc[key] ??= [];
          acc[key].push(item);
          return acc;
        },
        {} as Record<K, T[]>
      );
    };

    if (typeof Object.groupBy === 'undefined') {
      Object.groupBy = groupBy;
    }
  }
};
