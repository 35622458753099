'use client';
import type { IBookmarkItem } from '@/components/bookmarks/types';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import store from 'store';
import { BOOKMARK_KEY, getStoreBookmarks, sortBookmarks } from './utils';

interface BookmarkState {
  bookmarks: IBookmarkItem[];
  setBookmarks: React.Dispatch<React.SetStateAction<IBookmarkItem[]>>;
  sortedExistingBookmarks: IBookmarkItem[];
  getStoreBookmarks: () => IBookmarkItem[];
  saveBookmarksToStore: (bookmarks: IBookmarkItem[]) => void;
  updateBookmarks: (items: IBookmarkItem[]) => void;
}

const InitialBookmarkState = {
  bookmarks: [],
  setBookmarks: () => {},
  sortedExistingBookmarks: [],
  getStoreBookmarks: () => [],
  saveBookmarksToStore: () => {},
  updateBookmarks: () => {},
};

interface IBookmarkProviderProps {
  children: React.ReactNode;
}

const BookmarkContext = createContext<BookmarkState>(InitialBookmarkState);

export const BookmarkProvider = ({ children }: IBookmarkProviderProps) => {
  const [bookmarks, setBookmarks] = useState<IBookmarkItem[]>([]);

  const saveBookmarksToStore = (bookmarks: IBookmarkItem[]) => {
    const stringified = JSON.stringify(bookmarks);
    store.set(BOOKMARK_KEY, stringified);
    setBookmarks(bookmarks);
  };

  const updateBookmarks = useCallback((items: IBookmarkItem[]) => {
    saveBookmarksToStore(items);
  }, []);

  const sortedExistingBookmarks = useMemo(
    () => sortBookmarks(bookmarks),
    [bookmarks]
  );

  const existingBookmarks = useMemo(() => getStoreBookmarks(), []);

  useEffect(() => {
    setBookmarks(existingBookmarks);
  }, [existingBookmarks]);

  const value = useMemo(
    () => ({
      bookmarks,
      setBookmarks,
      sortedExistingBookmarks,
      getStoreBookmarks,
      saveBookmarksToStore,
      updateBookmarks,
    }),
    [bookmarks, sortedExistingBookmarks, updateBookmarks]
  );

  return (
    <BookmarkContext.Provider value={value}>
      {children}
    </BookmarkContext.Provider>
  );
};

export const useBookmarkStore = (): BookmarkState => {
  const bookmarkContext = useContext(BookmarkContext);
  if (!bookmarkContext) {
    throw new Error('useBookmarkStore must be used within a BookmarkProvider');
  }

  return bookmarkContext;
};
