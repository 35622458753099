import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useWebSocket } from './use-web-socket';
import { useGetSelf } from './useGetSelf';

const EVENT_IDENTIFIER_KEYS_TO_INVALIDATE_ON = [
  'instanceupdated',
  'instancedeleted',
  'instancecreated',
];

export const useReactQuerySubscription = () => {
  const queryClient = useQueryClient();
  const { data } = useGetSelf();
  const { lastMessageParsed: message } = useWebSocket();
  useEffect(() => {
    if (!data) {
      return;
    }
    if (!message || (message && message.invoke_type !== 0)) {
      return;
    }
    for (const event of message.pigello_events) {
      if (event.invoked_by_user_id === data.id) {
        continue;
      }
      const identifier = event.event_identifier.split('.').at(-1);
      if (
        !identifier ||
        !EVENT_IDENTIFIER_KEYS_TO_INVALIDATE_ON.includes(identifier)
      ) {
        continue;
      }
      const modelName = event.content_type.split('.').at(1);
      // queryClient.invalidateQueries({
      //   queryKey: [modelName, QUERY_KEYS.LIST],
      // });
      if (modelName && event.object_id) {
        queryClient.invalidateQueries({
          queryKey: [modelName, { id: event.object_id }],
        });
        // queryClient.invalidateQueries({
        //   queryKey: [
        //     EventLogConfig.modelName,
        //     QUERY_KEYS.LIST,
        //     { modelName: EventLogConfig.modelName },
        //   ],
        // });
      }
    }
  }, [queryClient, message, data]);
};
