'use client';

import { ThemeProvider as NextThemesProvider } from 'next-themes';
import type { ComponentProps } from 'react';
export function ThemeProvider({
  children,
  ...props
}: ComponentProps<typeof NextThemesProvider>) {
  return (
    <NextThemesProvider
      defaultTheme='light'
      themes={['system', 'light', 'dark']}
      {...props}
    >
      {children}
    </NextThemesProvider>
  );
}
