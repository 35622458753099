'use client';

import { TRACEABLE_CLIENT_TYPES } from '@/requests/constants';
import { datadogRum } from '@datadog/browser-rum';

function initDatadog() {
  if (
    process.env.NEXT_PUBLIC_CLIENT_TYPE &&
    TRACEABLE_CLIENT_TYPES.includes(process.env.NEXT_PUBLIC_CLIENT_TYPE) &&
    !datadogRum.getInitConfiguration()
  ) {
    datadogRum.init({
      applicationId: 'b3be2299-5e24-4674-839b-3fd8ec0416f4',
      clientToken: 'pub751b07243a6a8d19e8cb9e33b6155cf9',
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.eu',
      service: 'pigello-frontend',
      env: process.env.NEXT_PUBLIC_CLIENT_TYPE,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: process.env.NEXT_PUBLIC_COMMIT_HASH,
      sessionSampleRate: 50,
      sessionReplaySampleRate: 50,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      traceSampleRate: 100,
      defaultPrivacyLevel: 'mask-user-input',
      // Specify URLs to propagate trace headers for connection between RUM and backend trace
      allowedTracingUrls: [
        {
          match: window.location.host,
          propagatorTypes: ['datadog'],
        },
        {
          match: process.env.NEXT_PUBLIC_BASE_BACKEND_URL as string,
          propagatorTypes: ['datadog'],
        },
        {
          match: process.env.NEXT_PUBLIC_BASE_INTEGRATION_SERVICE_URL as string,
          propagatorTypes: ['datadog'],
        },
        {
          match: process.env.NEXT_PUBLIC_BASE_DASHBOARD_URL as string,
          propagatorTypes: ['datadog'],
        },
        {
          match: process.env.NEXT_PUBLIC_INTEGRATION_MONITOR_URL as string,
          propagatorTypes: ['datadog'],
        },
        {
          match: process.env.NEXT_PUBLIC_BASE_ADMIN_URL as string,
          propagatorTypes: ['datadog'],
        },
      ],
      beforeSend: (event, context) => {
        // collect a RUM resource's response headers
        if (
          event.type === 'resource' &&
          event.resource.type !== 'fetch' &&
          event.resource.type !== 'xhr' &&
          event.context &&
          'performanceEntry' in context
        ) {
          const isFromDiskCache =
            event.resource.transfer_size === 0 &&
            event.resource.decoded_body_size !== undefined &&
            event.resource.decoded_body_size > 0;
          event.context.diskCache = isFromDiskCache;
          // Not compatible with all browsers yet
          event.context.deliveryType =
            // no-dd-sa:typescript-code-style/ban-ts-comment
            // @ts-expect-error https://developer.mozilla.org/en-US/docs/Web/API/PerformanceResourceTiming/deliveryType#browser_compatibility
            context.performanceEntry?.deliveryType ?? undefined;
        }
        return true;
      },
    });
  }
}
export default function DatadogInit() {
  const isLocal = window.location.host.includes('localhost');
  if (process.env.NEXT_PUBLIC_CLIENT_TYPE === 'local' || isLocal) {
    throw new Error('Datadog RUM initialized in local environment');
  }
  initDatadog();
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
