'use client';

import useAuthState from '@/hooks/use-auth-state';
import { deleteCookie } from 'cookies-next';
import { useEffect, useState } from 'react';

export default function AuthWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    console.log('Deleting branding cookie on window reload');
    setIsClient(true);
    deleteCookie('branding');
  }, []);
  const isAuthorized = useAuthState();
  return isClient && isAuthorized ? <>{children}</> : <></>;
}
