'use client';
import { PigelloLogo } from '@/app/settings/organization/integrations/components/serviceLogo';
import { useGetOrganizationList } from '@/config/sales/organization/client';
import { useEnvironment } from '@/hooks/use-environment';
import { cn } from '@/lib/cn';
import { ClipboardDocumentIcon } from '@heroicons/react/16/solid';
import { getCookie } from 'cookies-next';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { toast } from 'sonner';
import { Button } from './ui/button';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';
import { Skeleton } from './ui/skeleton';

const BugReportInfo = () => {
  const customerId = getCookie('customer_id');
  const currentOrgId = getCookie('organization_id');
  const [isOpen, setIsOpen] = useState(false);

  const { isPigelloUser } = useEnvironment();

  const { data, isPending: isLoadingOrganizations } = useGetOrganizationList({
    queryParams: {
      page: 1,
      pageSize: 25,
    },
    enabled: isPigelloUser,
  });

  // We only want to show this for Pigello employees
  if (!isPigelloUser) return null;

  const currentOrg = data?.list.find((org) => org?.id === currentOrgId);
  const getInfo =
    () => `Kund-ID: ${customerId}\nNamn på miljö: ${currentOrg?.name}\n
Tidpunkt: ${DateTime.now().toISO()}\nURL: ${window.location}`;

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(getInfo());
      toast.success('Headers kopierade');
    } catch (error) {
      toast.error('Kunde inte kopiera headers');
    }
  };

  return (
    <Popover onOpenChange={(open) => !open && setIsOpen(open)}>
      <PopoverTrigger asChild onClick={() => setIsOpen(true)}>
        <button
          className={cn(
            'group fixed inset-x-0 top-0 z-50 mx-auto h-2 w-16 origin-top rounded-b-md bg-foreground transition-all hover:h-6 active:scale-95',
            { 'h-6': isOpen }
          )}
        >
          <PigelloLogo
            className={cn(
              'mx-auto w-4 opacity-0 transition-all group-hover:opacity-100',
              {
                'opacity-100': isOpen,
              }
            )}
          />
        </button>
      </PopoverTrigger>
      {isOpen && (
        <PopoverContent forceMount>
          <div className='flex flex-col gap-1'>
            <div className='flex items-center justify-between'>
              <p className='text-base'>Info till Jira</p>
              <Button
                onClick={() => copyToClipboard()}
                size={'icon-sm'}
                variant={'ghost'}
              >
                <ClipboardDocumentIcon className='size-4' />
              </Button>
            </div>

            {isLoadingOrganizations ? (
              <>
                <Skeleton className='w-full' />
                <Skeleton className='w-28' />
                <br />

                <Skeleton className='w-full' />
                <Skeleton className='w-32' />
              </>
            ) : (
              <p className='truncate whitespace-pre-wrap rounded-md border p-2'>
                {getInfo()}
              </p>
            )}
          </div>
        </PopoverContent>
      )}
    </Popover>
  );
};

export default BugReportInfo;
