'use client';

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { TextArea } from '@/components/ui/textarea';
import { usePromptStore } from '@/store';

export const PromptContextElement = () => {
  const { input, setInput, current: promptInfo, reset } = usePromptStore();

  const submit = () => {
    promptInfo?.callback?.(input);
    reset();
  };

  if (!promptInfo) return null;

  return (
    <AlertDialog open={!!promptInfo}>
      <AlertDialogContent>
        <AlertDialogHeader className='grid gap-2'>
          <AlertDialogTitle>
            {promptInfo?.title ?? 'Denna åtgärd kräver en motivering'}
          </AlertDialogTitle>
          <AlertDialogDescription>
            Vänligen ange orsaken till denna ändring så att godkännande part kan
            ta ställning till ändringen.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div className='grid gap-2'>
          <Label htmlFor='motivation'>Motivering *</Label>
          <TextArea
            id='motivation'
            className='resize-none'
            placeholder='Ange motivering'
            rows={4}
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
        </div>
        <AlertDialogFooter>
          <Button onClick={submit} disabled={input?.length < 3}>
            Spara
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
