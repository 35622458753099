import type { IBookmarkItem } from '@/components/bookmarks/types';
import store from 'store';

export const sortBookmarks = (unsortedBookmarks: IBookmarkItem[]) => {
  const folders = unsortedBookmarks.reduce((acc, cur) => {
    const folder = cur.folder;

    const existing = acc.find((f) => f?.[0]?.folder === folder);
    if (existing) {
      existing.push(cur);

      return acc;
    } else {
      return [...acc, [cur]];
    }
  }, [] as Array<IBookmarkItem>[]);

  folders.forEach((arr) => {
    arr.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 1));

    arr.forEach((bm, idx) => {
      bm.order = idx;
    });
  });

  const sorted = folders
    .flat()
    .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 1));

  return sorted;
};

export const BOOKMARK_KEY = 'bookmark_store' as const;

export const getStoreBookmarks = () => {
  const fromStore = store.get(BOOKMARK_KEY);
  if (fromStore) {
    try {
      const parsed = JSON.parse(fromStore) as IBookmarkItem[];
      return parsed;
    } catch (e) {
      return [];
    }
  } else {
    return [] as IBookmarkItem[];
  }
};
