'use client';

import { hexToHsl, useGetBranding } from '@/app/(auth)/hooks/use-get-branding';
import OverlayLoader from '@/components/ui/overlay-loader';
import { useEffect } from 'react';

export function BrandingProvider({ children }: { children: React.ReactNode }) {
  const { data: branding, isLoading } = useGetBranding();
  useEffect(() => {
    const doc = document.querySelector(':root') as HTMLElement;
    if (branding?.branding?.[0]?.colors?.primary) {
      doc?.style.setProperty(
        '--primary',
        `${hexToHsl(branding?.branding[0].colors.primary)}`
      );
    }
  }, [branding]);
  return (
    <>
      {isLoading && <OverlayLoader />}
      {children}
    </>
  );
}
