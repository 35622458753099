import { useGetSelf } from './useGetSelf';

export const securityClearance = (level: 'demo' | 'local') => {
  const hostname = window.location.hostname;
  if (level === 'local') {
    return hostname === 'localhost';
  }
  if (level === 'demo') {
    return hostname === 'demo.pigello.io' || hostname === 'localhost';
  }

  return false;
};

export const useEnvironment = () => {
  const { data: self } = useGetSelf();
  const hostname = window.location.hostname;
  const env = process.env.NODE_ENV;
  const isDev = env === 'development';

  const isDemo = hostname === 'demo.pigello.io';
  const isProd = hostname === 'app.pigello.io';
  const isPigelloUser =
    (self?.email?.endsWith('@pigello.se') && self?.emailVerified) ?? false;

  return {
    isProd,
    isDev,
    isDemo,
    isPigelloUser,
  } as const;
};
